import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { OrderService } from 'src/app/appServices/order.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-nmi-card-reader',
  templateUrl: './nmi-card-reader.component.html',
  styleUrls: ['./nmi-card-reader.component.scss'],
})
export class NmiCardReaderComponent extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  @Input() dataPayload: any;
  cardAnimationJson: string = "https://assets4.lottiefiles.com/packages/lf20_pu02tdjw.json";

  simulation:boolean = false;
  paymentSuccess:boolean = false;
 
  constructor(
    private orderService: OrderService,
    private infoStorage: RestaurantInfoResolver,
    private modalCtrl: ModalController,
    private toastMessage: ToastMessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.getBasicInfo();
    this.checkout();
  }

 
      
  /**
   * Checkout with payment intent
   */
  checkout() {
    if(!this.simulation){
      this.handleSubscription(this.orderService.createOrderByNMICardReader(this.dataPayload),
        (res) => {
          this.paymentSuccess = true;
        },
        (err) => {
          this.toastMessage.errorToastWithClose('Error in creating order.');
        }
      );
    }else{
      setTimeout(() => {
        this.paymentSuccess = true;
      }, 2000);
    }
  }
 

  /**
   * get basic information
   */
  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  closeModel(response): void {
    this.modalCtrl.dismiss(response);
  }
  

}
