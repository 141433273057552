import { Component, Input, OnInit, ChangeDetectorRef  } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { SettingsResolver } from 'src/app/appResolvers/settings.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { ProcessingFeeService } from 'src/app/appServices/processing-fee.service';
import { NumberUtility } from 'src/app/appUtilities/number.utility';
import { PaymentMethods } from 'src/app/core/enums/enums';
import { DesignUtilityServices } from 'src/app/core/utilities/design-utility.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { TermsStripeCardReaderComponent } from 'src/app/includes/terms-stripe-card-reader/terms-stripe-card-reader.component';

@Component({
  selector: 'app-charge-action-sheet',
  templateUrl: './charge-action-sheet.page.html',
  styleUrls: ['./charge-action-sheet.page.scss'],
})
export class ChargeActionSheetPage extends HandleSubscriptionsComponent implements OnInit {

  @Input() dataPayload: any;
  @Input() calculateSplittedFee: any;

  basicInfo: any;
  paymentMethods: any[];
  stripeProcessingFees: any = {};
  selectedStore: IStore;

  platformFee = 0;
  spendablePoints: number;
  remainingAmount: number;
  loyaltyPointValue: number;
  notEnoughPoints: boolean = false;
  isExpired: boolean = false;
  isLoyaltyMgmtEnabled: boolean = false;
  calculationsComplete: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private _paymentGatewayResolver: PaymentGatewayResolver,
    private infoStorage: RestaurantInfoResolver,
    private _settingResolver: SettingsResolver,
    private processingFeeService: ProcessingFeeService,
    private numberUtility: NumberUtility,
    private cashMgmtService: CashMgmtService,
    private toaster: ToastMessageService,
    private _storeResolver: StoreResolver,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.getBasicInfo();
    this.subscribePaymentMethods();
    this.subscribeStripeProcessingFees();
    this.getloyaltyPointsValue();
    this.subscribeSelectedStore();

    console.log("Payload: ", this.dataPayload);

    this.dataPayload.originalTotalAmount = this.dataPayload.totalAmount;
    this.calculationsComplete = false;

    if (this.dataPayload.processingFee) {
      this.dataPayload.totalAmount -= this.dataPayload.processingFee;
      delete this.dataPayload.processingFee;
    }

    this.calculateAllTotals();
  }

  calculateAllTotals() {
      this.calculationsComplete = false;

      // Reset totalAmount to original base amount
      this.dataPayload.totalAmount = this.dataPayload.originalTotalAmount;

      // If the selected payment method is not cash, apply additional calculations
      if (this.dataPayload.paymentMethod && this.dataPayload.paymentMethod.title !== 'Cash On Delivery') {
          this.dataPayload.totalAmount += this.calculateProcessingFee(this.dataPayload.paymentMethod);
          this.dataPayload.platformFee = this.getPlatformFeeFromRules(
              this.dataPayload.totalAmount,
              this.stripeProcessingFees.platformFeeRules,
              this.stripeProcessingFees?.platformFee
          );
          console.log('[DEBUG] Platform Fee During Total Calculation:', this.dataPayload.platformFee);
          this.dataPayload.totalAmount += this.dataPayload.platformFee;
      }
      if (this.dataPayload.paymentMethod && this.dataPayload.paymentMethod.title === 'NMI Card Reader') {
        this.dataPayload.platformFee = 0;
      }

      this.calculateSpendablePoints();
      this.calculationsComplete = true;
      this.cdr.detectChanges();

      console.log('[DEBUG] Final Total Amount:', this.dataPayload.totalAmount);
  }




  subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, (res) => {
      if (res) {
        this.getLoyaltyMgmtConfig(res.id);
      }
    });
  }

  getloyaltyPointsValue() {
    this.handleSubscription(this.cashMgmtService.getloyaltyPoints(),
      (res) => {
        this.loyaltyPointValue = +res.value;
        this.calculateSpendablePoints();
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: ' + err);
      }
    );
  }

  isSpecialTenantAndCoupon(): boolean {
    return this.basicInfo?.restaurantName === 'Bluntley' && this.dataPayload?.couponCode?.toUpperCase() === 'OWNER';
  }

  calculateSpendablePoints() {
    let totalAmount = this.dataPayload?.totalAmount || 0;

    if (this.isSpecialTenantAndCoupon()) {
      totalAmount = this.dataPayload?.originalTotalAmount || totalAmount;
    }

    const loyaltyPoints = this.dataPayload?.customer?.loyaltyPoints || 0;
    const pointsForTotalAmount = Math.ceil(totalAmount * this.loyaltyPointValue);
    this.spendablePoints = Math.min(pointsForTotalAmount, loyaltyPoints);

    const remainingAmountRaw = totalAmount - (this.spendablePoints / this.loyaltyPointValue);
    this.remainingAmount = Number(remainingAmountRaw) || 0;
    this.notEnoughPoints = loyaltyPoints < pointsForTotalAmount;

    const orderDate = new Date(this.dataPayload?.dateTime).getTime();
    if (this.dataPayload?.customer?.loyaltyPointsExpiration < orderDate) {
      this.isExpired = true;
    }
  }

  updateLoyaltyPointsBalance() {
    const loyaltyPoints = this.dataPayload?.customer?.loyaltyPoints || 0;
    this.dataPayload.customer.loyaltyPoints = loyaltyPoints - this.spendablePoints;
    this.dataPayload.loyaltyPointsSpent = this.spendablePoints;
  }

  squareTerminalAvailable: boolean = this.checkSquareTerminalDeviceId();

  checkSquareTerminalDeviceId() {
    return !!localStorage.getItem('squareTerminalDeviceId');
  }

  onSelectPaymentGateway(method) {
      if (method.title === 'Loyalty Points' && this.notEnoughPoints) {
          this.toaster.errorToastWithClose('Not enough loyalty points.');
          return;
      }

      this.dataPayload.paymentMethod = method;
      this.dataPayload.processingFee = this.calculateProcessingFee(this.dataPayload.paymentMethod) || 0;
      this.dataPayload.platformFee = this.getPlatformFeeFromRules(
          this.dataPayload.totalAmount,
          this.stripeProcessingFees.platformFeeRules,
          this.stripeProcessingFees?.platformFee
      );

      console.log("Data Payload: ", this.dataPayload);

      console.log('[DEBUG] Platform Fee Applied:', {
          paymentMethod: method.title,
          platformFee: this.dataPayload.platformFee,
      });

      if (method.title !== PaymentMethods.CashOnDelivery) {
          this.dataPayload.totalAmount = (this.dataPayload.totalAmount || 0) + this.dataPayload.processingFee;
          console.log('[DEBUG] Total Amount After Adding Platform Fee:', this.dataPayload.totalAmount);
      }

      if (method.title === 'Loyalty Points') {
          this.updateLoyaltyPointsBalance();
      }

      // console.log(this.dataPayload)
      // return
      this.dismissModal(true);
  }


  subscribePaymentMethods() {
    this.handleSubscription(this._paymentGatewayResolver.paymentMethods,
      (res) => {
        if (res) {
          this.paymentMethods = res;
        }
      }
    );
  }

  subscribeStripeProcessingFees() {
    this.handleSubscription(this._settingResolver.stripeProcessingFee,
      (res) => {
        this.stripeProcessingFees = res;
        console.log("Stripe Processing Fees: ", this.stripeProcessingFees);
      }
    );
  }

  calculateProcessingFee(paymentMethod?: any): number {
      if (!paymentMethod || !paymentMethod.name) {
        console.log('[DEBUG] Payment method is missing or undefined.');
        return 0;
      }

      if (paymentMethod.title === 'Cash On Delivery') {
        console.log('[DEBUG] Cash On Delivery selected. No processing fee applied.');
        return 0;
      }

      // if (paymentMethod.title === 'NMI Card Reader') {
      //   console.log('[DEBUG] NMI Card Reader selected. No processing fee applied.');
      //   return 0;
      // }

      if (this.calculateSplittedFee) {
          const splittedFee = this.calculateSplittedFee.find(fee => fee.title === paymentMethod.title);
          console.log('[DEBUG] Splitted fee found:', splittedFee);
          return splittedFee ? splittedFee.fee : 0;
      }

      let calculatedFee = 0;

      if (paymentMethod.name === 'Square Terminal') {
          calculatedFee = this.processingFeeService.calculateSquareTerminalProcessingFee(
              paymentMethod.title,
              this.dataPayload.totalAmount,
              this.stripeProcessingFees
          );
      } else if (paymentMethod.name === 'Square Card') {
          calculatedFee = this.processingFeeService.calculateSquareCardProcessingFee(
              paymentMethod.title,
              this.dataPayload.totalAmount,
              this.stripeProcessingFees
          );
      } else if (paymentMethod.name === 'NMI Mannual Card') {
          // calculatedFee = this.processingFeeService.calculateSquareCardProcessingFee(
          //     paymentMethod.title,
          //     this.dataPayload.totalAmount,
          //     this.stripeProcessingFees
          calculatedFee = 0;
          // );
      } else if (paymentMethod.name === 'NMI Card Reader') {
          calculatedFee = 1.75+.20;
      } else {
          calculatedFee = this.processingFeeService.calculateStripeProcessingFee(
              paymentMethod.title,
              this.dataPayload.totalAmount,
              this.stripeProcessingFees
          );
      }

      console.log('[DEBUG] Processing Fee Calculated:', {
          paymentMethod: paymentMethod.title,
          totalAmount: this.dataPayload.totalAmount,
          calculatedFee
      });

      return calculatedFee;
  }




  dismissModal(result: boolean) {
    if (result === false) {
      // If canceled, restore the original amount
      this.dataPayload.totalAmount = this.dataPayload.originalTotalAmount;
      delete this.dataPayload.paymentMethod; // Clear the payment method
    }
    this.modalCtrl.dismiss();
    this.cdr.detectChanges(); // Ensure UI reflects the reset amount
  }


  getBasicInfo() {
    // localStorage.removeItem('basic-info');
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  async openTermsAndConditions() {
    if (this.basicInfo?.businessType === 'restaurant') {
      const modal = await this.modalCtrl.create({
        component: TermsStripeCardReaderComponent,
        componentProps: {
          data: this.dataPayload,
        },
      });
      await modal.present();
    } else {
      this.toaster.errorToastWithClose('Terms and conditions are only available for restaurant businesses.');
    }
  }

  getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[], defaultPlatformFee: number): number {
      console.log('[DEBUG] Inputs to getPlatformFeeFromRules:', {
          totalAmount,
          platformFeeRules,
          defaultPlatformFee
      });

      if (!platformFeeRules || !Array.isArray(platformFeeRules)) {
          console.error('[ERROR] Invalid platformFeeRules:', platformFeeRules);
          return defaultPlatformFee || 0;
      }

      let result = 0;
      for (let i = 0; i < platformFeeRules.length; i++) {
          const min = platformFeeRules[i].minOrderAmount;
          const max = platformFeeRules[i].maxOrderAmount;

          console.log(`[DEBUG] Rule ${i}:`, {
              minOrderAmount: min,
              maxOrderAmount: max,
              fee: platformFeeRules[i].fee,
          });

          if (totalAmount > min && totalAmount <= max) {
              console.log('[DEBUG] Matching Rule Found:', {
                  minOrderAmount: min,
                  maxOrderAmount: max,
                  fee: platformFeeRules[i].fee,
              });
              result = platformFeeRules[i].fee;
              break;
          }
      }

      const platformFee = result > 0 ? result : defaultPlatformFee;

      console.log('[DEBUG] Final Platform Fee Calculation:', {
          totalAmount,
          platformFeeRules,
          result,
          defaultPlatformFee,
          calculatedFee: platformFee,
      });

      return platformFee;
  }



  getLoyaltyMgmtConfig(storeId: number) {
    this.handleSubscription(this.cashMgmtService.getConfigurationByStore('loyaltyMgmt', storeId),
      (res) => {
        this.isLoyaltyMgmtEnabled = res.value === '1';
        if (res == null) {
          this.isLoyaltyMgmtEnabled = false;
        }
      }
    );
  }
}
