import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { ModalController } from '@ionic/angular';
import { OrderService } from 'src/app/appServices/order.service';
import { CartResolver } from 'src/app/appResolvers/cart.resolver';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { NumberUtility } from 'src/app/appUtilities/number.utility';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';


@Component({
  selector: 'app-nmi-manual-card',
  templateUrl: './nmi-manual-card.component.html',
  styleUrls: ['./nmi-manual-card.component.scss'],
})
export class NmiManualCardComponent extends HandleSubscriptionsComponent implements OnInit {

  basicInfo: any;
  loading: boolean = false;
  @Input() data: any;
  Form: UntypedFormGroup;

  submitted:boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private cartResolver: CartResolver,
    private orderService: OrderService,
    private modalCtrl: ModalController,
    public toastMessage: ToastMessageService,
    private _basicInfoResolver: RestaurantInfoResolver,
    private numberUtility: NumberUtility,
    private paymentGatewayResolver: PaymentGatewayResolver,
  ) {
    super();
  }


  
  ngOnInit(): void {
    this.initForm();
    this.subscribeBasicInfo();
  }

  
  /**
   * init
   */
  initForm() {
    this.Form = this.fb.group({
      cardNumber: ['4539781111111111', [Validators.required, Validators.minLength(16), Validators.maxLength(16), Validators.pattern(/^[0-9]+$/)]],
      expDate: ['1225', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])(2[0-9]|3[0-9])$/)]],
      cvv: ['233', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(/^[0-9]+$/)]],
    });
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
      }
    );
  }

  

  onSubmit() {
    console.log(this.Form);
    console.log(this.data);
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;

    const nmiPaymentModel = {
      cardNumber: this.Form.value.cardNumber,
      expDate: this.Form.value.expDate,
      cvv: this.Form.value.cvv,
    };
    this.submitted = true;

    this.calculateFee();
    console.log('Payment Form Submitted:', nmiPaymentModel); 
   
  }

  async calculateFee(){
    const cardBrand = this.getCardBrand(this.Form.value.cardNumber);
    this.getCardType2(cardBrand, this.Form.value.cardNumber);
    
    // const cardType = await this.getCardType(this.Form.value.cardNumber);
    // const interchangeRates = this.getInterchangeRates(cardBrand, cardType)

    console.log('cardBrand => ',cardBrand);
    // console.log('cardType => ',cardType);
    // console.log('interchangeRates => ',interchangeRates);
  }


  createPayload(){
    // this.data.totalAmount += this.calculateProcessingFee(this.data.paymentMethod);
    // this.data.processingFee = this.calculateProcessingFee(this.data.paymentMethod) || 0;
  }

  calculateProcessingFee(){
    // scema or formula to calculate fee
  }




  getCardBrand(cardNumber: string): string {
    const patterns: { [key: string]: RegExp } = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      MasterCard: /^5[1-5][0-9]{14}$/,
      AmericanExpress: /^3[47][0-9]{13}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      UnionPay: /^62[0-9]{14,17}$/,
      Maestro: /^(?:5[0678]\d{0,2}|6\d{0,2})\d{12,19}$/,
    };
  
    for (const [cardType, regex] of Object.entries(patterns)) {
      if (regex.test(cardNumber)) {
        return cardType;
      }
    }
  
    return 'Unknown';
  }

  getCardType2(cardBrand:string, cardNumber: string) {
    const IINumber = +cardNumber.substring(0, 6);
    console.log('IINumber=> ',IINumber)
    this.handleSubscription(this.paymentGatewayResolver.getNmiCardType(IINumber),
        (res) => {
          this.loading = false;
          console.log('IINumber res=> ',res.result);
          const cardType = res.result;
          const interchangeRates = this.getInterchangeRates(cardBrand, cardType)
          console.log('interchangeRates => ',interchangeRates);
          // this.postPayment();
        },
        (err) => {
          this.loading = false;
          // this.toastMessage.errorToastWithClose(
          //   'Error in creating order, If any money detected from your account will be refund.'
          // );
        }
      );
  }

  async getCardType(cardNumber: string): Promise<string> {
    // Simulate calling the external API to determine card type (credit, debit, etc.)
    const response = await fetch('https://api.nmi.com/card-type', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cardNumber }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch card type from the API.');
    }
  
    const data = await response.json();
    return data.card_type || 'Unknown';
    // return { brand: data.card_brand || 'Unknown', type: data.card_type || 'Unknown' };
  }

  getInterchangeRates(cardBrand: string, cardType: string): { percentage: number; fixed: number } {
    const interchangeRates: { [key: string]: { [key: string]: { percentage: number; fixed: number } } } = {
      Visa: {
        credit: { percentage: 1.51, fixed: 0.10 },
        debit: { percentage: 0.80, fixed: 0.05 },
        prepaid: { percentage: 1.15, fixed: 0.10 },
      },
      MasterCard: {
        credit: { percentage: 1.55, fixed: 0.10 },
        debit: { percentage: 0.85, fixed: 0.05 },
        prepaid: { percentage: 1.20, fixed: 0.10 },
      },
      Discover: {
        credit: { percentage: 1.58, fixed: 0.10 },
        debit: { percentage: 0.90, fixed: 0.05 },
        prepaid: { percentage: 1.25, fixed: 0.10 },
      },
      AmericanExpress: {
        credit: { percentage: 2.00, fixed: 0.15 },
        debit: { percentage: 1.50, fixed: 0.10 },
        prepaid: { percentage: 1.70, fixed: 0.15 },
      },
      Default: {
        credit: { percentage: 1.75, fixed: 0.10 },
        debit: { percentage: 1.50, fixed: 0.10 },
        prepaid: { percentage: 1.60, fixed: 0.10 },
      },
    };
  
    const brandRates = interchangeRates[cardBrand] || interchangeRates['Default'];
    return brandRates[cardType] || { percentage: 1.75, fixed: 0.10 }; // Default to 1.75% + $0.10
  }


  // async calculateInterchangePlusFee(
  //   totalAmount: number,
  //   cardNumber: string
  // ): Promise<number> {
  //   const additionalPercentage = 0.60; // Additional(platform fee) percentage fee
  //   const additionalFixed = 0.20; // Additional fixed fee
  
  //   const { brand: cardBrand, type: cardType } = await getCardType(cardNumber);
  //   const { percentage: interchangePercentage, fixed: interchangeFixed } = getInterchangeRates(cardBrand, cardType);
  
  //   if (totalAmount <= 0) {
  //     throw new Error('Total amount must be greater than zero.');
  //   }
  
  //   // Calculate interchange fees
  //   const interchangeFee = (totalAmount * interchangePercentage) / 100 + interchangeFixed;
  
  //   // Calculate additional fees
  //   const additionalFee = (totalAmount * additionalPercentage) / 100 + additionalFixed;
  
  //   // Calculate total fees
  //   const totalFee = interchangeFee + additionalFee;
  
  //   return parseFloat(totalFee.toFixed(2)); // Return total fee rounded to 2 decimal places
  // }



  onConfirm(){

  }

     



  chargeCard(): void {
    if (this.data.type === "pay by paymentIntent") {
      this.payPartialAmount();
    } else if (this.data.id) {
      this.paySavedOrderByOrderId();
    } else {
      this.payOrder();
    }
  }


  /**
   * pay order with out order id 
   */
  payOrder() {
    const onlinePaymentModel = {
      totalAmount: this.data.totalAmount,
      orderModel: this.data,
      nmiPaymentModel: {
        cardNumber: this.Form.value.cardNumber,
        expDate: this.Form.value.expDate,
        cvv: this.Form.value.cvv,
      }
    };
    this.handleSubscription(this.orderService
      .makeCreditCardPayment(onlinePaymentModel, 'nmi'),
        (res) => {
          this.loading = false;
          this.data.id = res;
          this.postPayment();
        },
        (err) => {
          this.loading = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }




  /**
   * pay saved order
   */
  paySavedOrderByOrderId() {
    const onlinePaymentModel = {
      totalAmount: this.data.totalAmount,
      processingFee: this.data.processingFee,
      platformFee: this.data.platformFee,
      nmiPaymentModel: {
        cardNumber: this.Form.value.cardNumber,
        expDate: this.Form.value.expDate,
        cvv: this.Form.value.cvv,
      }
    }
    this.handleSubscription(this.orderService
      .markOrderAsPaidByNmiManualCard(this.data.id, onlinePaymentModel),
        (res) => {
          this.loading = false;
          this.postPayment();
        },
        (err) => {
          this.loading = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );
  }




  /**
   * pay partial amount 
   */
  payPartialAmount() {
    const onlinePaymentModel = {
      amount: this.numberUtility.roundToTwo(this.data.totalAmount),
      processingFee: this.data.processingFee,
      paymentType: 'NMI',
      platformFee: this.data.platformFee,
      nmiPaymentModel: {
        cardNumber: this.Form.value.cardNumber,
        expDate: this.Form.value.expDate,
        cvv: this.Form.value.cvv,
      }
    };

    this.handleSubscription(this.orderService
      .payPartialAmountByNmiManualCard(this.data.id, onlinePaymentModel),
        (res) => {
          this.loading = false;
          this.data.id = res;
          this.postPayment();
        },
        (err) => {
          this.loading = false;
          this.toastMessage.errorToastWithClose(
            'Error in creating order, If any money detected from your account will be refund.'
          );
        }
      );

  }


  postPayment(): void {
    this.cartResolver.cart.next([]);
    //this.toastMessage.successToastWithClose('Order is successfully placed');
    this.onCloseModel();
  }


  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  onCloseModel(): void {
    this.modalCtrl.dismiss(this.data);
  }
}
