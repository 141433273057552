import { Injectable } from "@angular/core";
import { HttpConfigService } from "../core/httpConfig/httpConfigService";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class StationService {

  api = this.apiConfig.getBaseUrl;
  selectedStation = new BehaviorSubject<any>(null);

  constructor(private apiConfig: HttpConfigService) {
  }


  /**
   * get all stores info
   **/
  getAllStations(storeId): Observable<any[]> {
    return this.apiConfig.get(`${this.api}station/${storeId}`);
  }


  /**
   * set selected store
   * @param store 
   */
  public setSelectedStation(station: any) {
    this.selectedStation.next(station);
  }
  

  /**
   * create station
   * @param station 
   * @returns 
   */
  createStation(station: any): Observable<any> {
    return this.apiConfig.post(this.api+'station', station);
  }
  

  /**
   * get device
   * @param stationId 
   * @param deviceType 
   * @returns 
   */
  getDevice(stationId: number, deviceType: string) : Observable<any[]> {
    return this.apiConfig.get(`${this.api}station/${stationId}/${deviceType}`);
  }


  /**
   * patch device code
   * @param stationId 
   * @param deviceType 
   * @returns 
   */
  patchDeviceCode(stationId: number, deviceType: string, deviceCode: string) : Observable<any[]> {
    return this.apiConfig.patch(`${this.api}station/${stationId}/${deviceType}/${deviceCode}`);
  }

}