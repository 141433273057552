import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IStore } from '../appModels/IStore';
import { StoresService } from '../appServices/stores.service';
import { LoaderUtility } from '../appUtilities/loader.utility';
import { ToastMessageService } from '../core/utilities/toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class StoreResolver {
  changeStoreClick = new Subject();
  storeList = new BehaviorSubject<IStore[]>(null);
  selectedStore = new BehaviorSubject<IStore>(null);

  constructor(
    private _storeService: StoresService,
    private _loaderUtility: LoaderUtility,
    private navCtrl:NavController,
    private toasterService: ToastMessageService,
    ) {

  }



  /**
   * Setup subscribe store list
   */
   async setupStore(set?:boolean) {
    if(this.storeList.value) {
      return
    }
    
    await this._loaderUtility.showLoader();
    this._storeService.getAllStores().subscribe(
      async (res: IStore[]) => {
        await this._loaderUtility.hideLoader();
        this.setStoreList(res);
        if(set) this.changeStoreClick.next(null);
      },
      async (err)=>{
        await this._loaderUtility.hideLoader();
      }
    );
  }


  
  /**
   * Set Store list
   * @param storeList 
   */
   setStoreList(storeList: IStore[]) {
    this.storeList.next(storeList);
  }


  


  /**
   * Get Stripe account by store id
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
  getStripeAcByStoreId(storeId): Observable<any> {
    return this._storeService.getStripeAcByStoreId(storeId);
  }


  /**
   * Get Square account by store id
   * @param storeId 
   * @returns 
   */
  getSquareAcByStoreId(storeId): Observable<any> {
    return this._storeService.getSquareAcByStoreId(storeId);
  }


  
  /**
   * Get Square account by store id
   * @param storeId 
   * @returns 
   */
  getAeropayAcByStoreId(storeId): Observable<any> {
    return this._storeService.getAeropayAcByStoreId(storeId);
  }


  /**
   * Get Rethink account by store id
   * @param storeId 
   * @returns 
   */
  getRethinkAcByStoreId(storeId): Observable<any> {
    return this._storeService.getRethinkAcByStoreId(storeId);
  }



  /**
   * Get Nmi account by store id
   * @param storeId 
   * @returns 
   */
  getNmiAcByStoreId(storeId): Observable<any> {
    return this._storeService.getNmiAcByStoreId(storeId);
  }


  /**
   * Map Stripe account with Store 
   * @param storeId 
   * @param stripeAccountId 
   * @returns 
   */
   mapAccountWithStore(storeId, stripeAccountId): Observable<any> {
    return this._storeService.mapAccountWithStore(storeId, stripeAccountId);
  }

  
  /**
   * Map Rethink account with Store
   * @param storeId 
   * @param rethinkAccountId 
   * @returns 
   */
  mapRethinkAccountWithStore(storeId, rethinkAccountId): Observable<any> {
    return this._storeService.mapRethinkAccountWithStore(storeId, rethinkAccountId);
  }


  /**
   * Map Square account with Store 
   * @param storeId 
   * @param squareId 
   * @returns 
   */
  mapSquareAccountWithStore(storeId, squareId): Observable<any> {
    return this._storeService.mapSquareAccountWithStore(storeId, squareId);
  }



  /**
   * Map aeropay account with Store 
   * @param storeId 
   * @param accountId 
   * @returns 
   */
  mapAeropayAccountWithStore(storeId, accountId): Observable<any> {
    return this._storeService.mapAeropayAccountWithStore(storeId, accountId);
  }


  /**
   * set selected store
   * @param store 
   */
  public setSelectedStore(store: IStore) {
    this.selectedStore.next(store);
  }


  /**
   * clear Selected store
   */
  public clearSelectedStore(): void {
    this.selectedStore.next(null);
  }

  

  getStoreListByStripeAccount(stripeAccountId: string): Observable<any> {
    return this._storeService.getStoreListByStripeAccount(stripeAccountId);
  }


   


  





  /**
   * Create New Store
   * @param storeData 
   * @returns 
   */
  async createNewStore(storeData: any) {
    await this._loaderUtility.showLoader();
    this._storeService.createNewStore(storeData).subscribe(async res => {
      this.subscribeStoreList(true);
      this.toasterService.successToastWithClose('Successfully created.');
      this.navCtrl.navigateForward('/outlet');
      await this._loaderUtility.hideLoader();
    }, async err => {
      this.toasterService.errorToastWithClose('Error: ' + err.error.message);
      await this._loaderUtility.hideLoader();
    });
  }




  /**
   * Update Store
   * @param store 
   */
  updateStore(store: IStore) {
    this._storeService.editStore(store).subscribe(res => {
      this.toasterService.successToastWithClose('Successfully updated.');
      this.navCtrl.navigateForward('/outlet');
      this.postUpdate(store);
    }, err => {
      this.toasterService.errorToastWithClose('Unable to update, Please contact us');
    });
  }
  
  



  /**
   * subscribe store list
   */
  subscribeStoreList(relode: boolean = false) {
    if (!relode && this.storeList.value) {
      return
    }
    this._storeService.getAllStores().subscribe((res: IStore[]) => {
      this.setStoreList(res);
      this.setSelectedStore(this.storeList.value[0] || null);
    });
  }


  


  /**
   * Get Store by Id
   * @param id 
   * @returns 
   */
  getStoreById(id: number) {
    if (this.storeList.value && this.storeList.value.length > 0) {
      const storeListCopy = [...this.storeList.value];
      const temp = storeListCopy.findIndex(e => e.id === id);
      return of(storeListCopy[temp]);
    } else {
      return this._storeService.getStoreById(id);
    }
  }

  /**
   * Toggle Store Active
   * @param store 
   */
  activateStore(store: IStore) {
    this._storeService.updateActive(store.id, store.active).subscribe(res => {
      this.toasterService.successToastWithClose('Successfully updated.');
      this.postUpdate(store);
    }, err => {
      this.toasterService.errorToastWithClose('Unable to update, Please contact us.: ' + err);
      store.active = !store.active;
      this.postUpdate(store);
    });
  }


  

  /**
   * Post Update
   * @param store 
   */
  postUpdate(store: IStore) {
    const storeListCopy = [...this.storeList.value];
    const temp = storeListCopy.findIndex(e => e.id === store.id);
    storeListCopy[temp] = store;
    this.setStoreList(storeListCopy);
  }
  

  /**
   * Delete Store
   * @param id 
   */
  deleteStore(id:any) {
    this._storeService.deleteStore(id).subscribe(res => {
      const storeListCopy = [...this.storeList.value];
      const index = storeListCopy.findIndex(e => e.id === id);
      storeListCopy.splice(index, 1);
      this.setStoreList(storeListCopy);
      this.toasterService.successToastWithClose('Successfully deleted.');
    }, err => {
      this.toasterService.errorToastWithClose('Error: ' + err);
    });
  }




  /**
   * Clear storage
   */
  clear() {
    this.storeList.next(null);
    this.selectedStore.next(null);
  }
}
