import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { AddNmiAccountComponent } from './add-nmi-account/add-nmi-account.component';

@Component({
  selector: 'app-nmi-account-modal',
  templateUrl: './nmi-account-modal.component.html',
  styleUrls: ['./nmi-account-modal.component.scss'],
})
export class NmiAccountModalComponent extends HandleSubscriptionsComponent implements OnInit {
  emailId: string;
  adding:boolean = false;
  accounts: any[];
  loading: boolean = false;
  selectedStore: IStore;
  getAllAccountsSub:Subscription;

  @Input() selectedNmiAccount: number;
  
  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private toaster: ToastMessageService,
    private _paymentGatewayResolver: PaymentGatewayResolver

  ) { 
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getAllAccounts();

  }
  

  /**
  * subscribe selected store
  */
   subscribeSelectedStore() {
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore= res;
      }
    });
  }

 

  /**
   * Get All Accounts
   */
  getAllAccounts() {
    this.getAllAccountsSub = this._paymentGatewayResolver.getAllNmiAccounts().subscribe(res => {
      console.log('nmi account ',res)
      this.accounts = res;
    })
  }

  async addNewAccount(){
    const modal = await this.modalCtrl.create({
      component: AddNmiAccountComponent,
      cssClass: 'rounded-modal big',
      backdropDismiss: true,
      mode: 'md'
    });
    await modal.present();
    const { role , data} = await modal.onWillDismiss();
    if (role || data){
      // Do something after add
    }
  }

  findAccountByEmail() {
    this.loading = true;
    this.handleSubscription(this._paymentGatewayResolver.findAccountByEmail(this.emailId), 
    (res) => {
      console.log('res => ',res)
      if (res.message =='No Account Found'){
        this.toaster.warningToastWith10Seconds('No Account Found with the email id '+ this.emailId)
      }else if (res.id){
        this.addExistingAccount(res.id);
      }
      // this.getAllAccounts();
      // this.adding = false;
      this.loading = false;
      // this.redirectToStripe(res?.onboardUrl);
    });
  }


  addExistingAccount(id:any) {
    this.loading = true;
    const payload ={
      emailId: this.emailId,
      accountId:+id
    }
    this.handleSubscription(this._paymentGatewayResolver.addExistingNMIAccount(payload), 
    (res) => {
      this.getAllAccounts();
      this.adding = false;
      this.loading = false;
      // this.redirectToStripe(res?.onboardUrl);
    });
  }



  redirectToStripe(url) {
    var popup = window.open(url, "_blank");
    setTimeout(function () {
      if (!popup || popup.outerHeight === 0) {
        //First Checking Condition Works For IE & Firefox
        //Second Checking Condition Works For Chrome
        alert("Click OK to setup strip account...");
        window.location.href = url;
      } else {
        //Popup Blocker Is Disabled
        window.open(url, '_blank');
        window.close();
      }
    }, 25);
  }


  mapToStore(accountId){
    this.handleSubscription(this._storeResolver.mapAccountWithStore(this.selectedStore.id, accountId), 
    (res) =>{
      this.modalCtrl.dismiss(accountId);
    })
  }


  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  /**
   * Destroy Component
   */
   ngOnDestroy(): void {
    this.getAllAccountsSub.unsubscribe();
  }

}
