import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { SelectStationComponent } from './select-station/select-station.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [SendNotificationComponent, SelectStationComponent]
})
export class CommonPageModule {}
