import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrderType } from 'src/app/appModels/IOrderType';
import { ClientTypeService } from './client-type.service';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { Router } from '@angular/router'; // Import Router

@Injectable({
  providedIn: 'root'
})
export class ClientTypeResolver {

  selectedOrderType = new BehaviorSubject<IOrderType>(null);
  orderTypeList = new BehaviorSubject<IOrderType[]>(null);

  constructor(
    private _clientTypeService: ClientTypeService,
    private router: Router // Inject the Router

  ) {
  }

  /**
   * Get all clients
   */
  getAllClients(): Observable<any> {
    return this._clientTypeService.getAllClients();
  }

  /**
   * Get all order type
   * @returns
   */
  getAllOrderType(): Observable<any> {
    return this._clientTypeService.getAllOrderType();
  }



  /**
   * Get order type list
   * @param storeId
   */
  public getOrderTypeList(storeId: number) {
    this._clientTypeService.getOrderTypeList(storeId).subscribe(res => {
      if (res) {
        this.orderTypeList.next(res);
        const basicInfo = JSON.parse(localStorage.getItem("basic-info"));

        console.log('basicInfo: client-type-resolver: ',basicInfo);
        if (basicInfo.businessType === 'cannabis') {
          const targetOrderTypeName = 'Retail';
          this.setSelectedOrderType(this.orderTypeList.value.find(
            (orderType) => orderType.orderTypeName.toLowerCase() === targetOrderTypeName.toLowerCase()
          ) || this.orderTypeList.value[0]);
        } else {
          this.setSelectedOrderType(this.orderTypeList.value[0]);
        }
      }
    });
  }

  setSelectedOrderType(orderType: IOrderType) {
    this.selectedOrderType.next(orderType);
  }

  /**
   * Toggle order type
   * @param storeId
   * @param clientTypeId
   * @param orderTypeId
   * @param active
   * @returns
   */
  toggleOrderType(storeId, clientTypeId, orderTypeId, active): Observable<any> {
    return this._clientTypeService.toggleOrderType(storeId, clientTypeId, orderTypeId, active);
  }

  /**
   * Clear storage
   */
  clear() {
    this.orderTypeList.next(null);
    this.selectedOrderType.next(null);
  }
}
