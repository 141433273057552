import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { PaymentMethodsPage } from 'src/app/pages/settings/payment-methods/payment-methods.page';
import { AddInitialCashComponent } from '../components/add-initial-cash/add-initial-cash.component';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { PricingPlansComponent } from '../components/pricing-plans/pricing-plans.component';
import { ManageAliasComponent } from '../components/manage-alias/manage-alias.component';
import { SetupTerminalComponent } from '../components/setup-terminal/setup-terminal.component';
import { UserReportComponent } from 'src/app/pages/settings/user-report/user-report.component'; // Corrected path
import { TermsAndConditionsComponent } from 'src/app/includes/terms-and-conditions/terms-and-conditions.component'; // Adjust the path as necessary
import { SetLoyaltyPointsComponent } from '../components/set-loyalty-points/set-loyalty-points.component';
import { RecallFeatureComponent } from 'src/app/pages/settings/recall-feature/recall-feature.component'; // Adjust the path as necessary
import { DisabledAccountComponent } from '../components/disabled-account/disabled-account.component';

@Injectable({
  providedIn: 'root'
})
export class DesignUtilityServices {

  /**
   * Form Vaidators
   */
  validator = {
    // mobile: '^((\\+91-?)|0)?[0-9]{11}$', // For prod
    mobile: '^((\\+91-?)|0)?([0-9]{10})$', // For preprod
    mobile10And11: /^((\+91-?)|0)?([0-9]{10}|[0-9]{11})$/,
    // email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    email: '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,9}(?:\\.[A-Za-z]{2,9})?)$',
    otp: '^[0-9]*$',
    pin: '^[0-9]*$',
    price: '',
    noWhiteSpace: '\S'
  }

  openInitialCashModalEvent = new Subject();
  openTaxSettingModalEvent = new Subject();

  get intialCash(){
    return this.cashMgmtService.intialCash.value;
  }

  constructor(
    private modalCtrl: ModalController,
    private cashMgmtService: CashMgmtService,
    private router: Router) { }

  /**
   * Scroll to Active
   */
  scrollToActive() {
    const scrollItem = document.querySelectorAll('.scrlItem');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add('active-on-scroll');
        }
        // else {
        //   entry.target.classList.remove('active-on-scroll');
        // }
      });
    });

    scrollItem.forEach(image => {
      observer.observe(image);
    });
  }


  async openRecallFeatureModal() {
      if (await this.isModalOpen()) return;

      const modal = await this.modalCtrl.create({
        component: RecallFeatureComponent,
        cssClass: 'rounded-modal',
        backdropDismiss: true,
        mode: 'md',
      });
      await modal.present();
      const { role, data } = await modal.onWillDismiss();
    }

  /**
   * Check if a modal is already open
   */
  async isModalOpen(): Promise<boolean> {
    const topModal = await this.modalCtrl.getTop();
    return !!topModal;
  }

  async openUserReportModal() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: UserReportComponent,
      cssClass: 'rounded-modal',
      backdropDismiss: true,
      mode: 'md',
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
  }

  /**
   * open Payment Methods
   */
  async openPaymentMethods() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: PaymentMethodsPage,
      cssClass: 'rounded-modal',
      backdropDismiss: true,
      mode: 'md',
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (role || data) {
      if (this.router.url.startsWith("/pos")) {
        this.openInitialCashModalEvent.next(null);
      }
    }
  }


  async openDisbaledAccountModel() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: DisabledAccountComponent,
      cssClass: 'rounded-modal',
      backdropDismiss: false,
      mode: 'md',
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    
  }


  /**
   * Open alias modal
   */
  async openAliasModal() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: ManageAliasComponent,
      mode: 'md',
      cssClass: 'rounded-modal sml',
      backdropDismiss: false,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
  }

  /**
   * Add initial cash
   */
  async addInitialCash() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: AddInitialCashComponent,
      mode: 'md',
      cssClass: 'rounded-modal sml',
      backdropDismiss: false,
      componentProps: {
        intialCash: this.intialCash
      }
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (role || data) {
      if (this.router.url.startsWith("/pos")) {
        this.openTaxSettingModalEvent.next(null);
      }
    }
  }


  /**
   * Set loyalty points
   */
  async setLoyaltyPoints() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: SetLoyaltyPointsComponent,
      mode: 'md',
      cssClass: 'rounded-modal sml',
      backdropDismiss: false
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (role || data) {

    }
  }


  /**
   * Setup Terminal
   */
  async setupTerminal() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: SetupTerminalComponent,
      mode: 'md',
      cssClass: 'rounded-modal sml',
      backdropDismiss: false,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
  }

  /**
   * Open pricing plan modal
   */
  async openPricingPlanModal() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: PricingPlansComponent,
      mode: 'md',
      cssClass: 'rounded-modal big',
      backdropDismiss: false,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
  }

  async openTermsAndConditionsModal() {
    if (await this.isModalOpen()) return;

    const modal = await this.modalCtrl.create({
      component: TermsAndConditionsComponent,
      cssClass: 'rounded-modal big',
      backdropDismiss: true,
      mode: 'md',
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
  }

  //------------------------------------------------------//
  // Validate for space at start                          //
  //-----------------------------------------------------//
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').match(/^\s/g);
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
