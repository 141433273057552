import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { SettingsResolver } from 'src/app/appResolvers/settings.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { OrderService } from 'src/app/appServices/order.service';
import { ProcessingFeeService } from 'src/app/appServices/processing-fee.service';
import { StripePaymentService } from 'src/app/appServices/stripe-payment.service';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { CustomDiscountComponent } from 'src/app/includes/popups/custom-discount/custom-discount.component';
import { StationService } from 'src/app/appServices/station.service';

@Component({
  selector: 'app-stripe-card-reader',
  templateUrl: './stripe-card-reader.component.html',
  styleUrls: ['./stripe-card-reader.component.scss'],
})
export class StripeCardReaderComponent extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  terminal: any;
  readerStatus = 'Not connected';
  paymentStatus = 'Pending';
  paymentDone = false;
  change = 0;
  connectionToken: string;
  cardReaderNumber: string;
  cardReaderInitiated = false;  // New variable to track if card reader was initiated
  @Input() dataPayload: any;
  cardAnimationJson: string = "https://assets4.lottiefiles.com/packages/lf20_pu02tdjw.json";

  errorLog: string;
  selectedStore: IStore;
  stripeProcessingFees: any = {};
  station: any;
  device: any;

  constructor(
    private orderService: OrderService,
    private stripePaymentService: StripePaymentService,
    private infoStorage: RestaurantInfoResolver,
    private modalCtrl: ModalController,
    private processingFeeService: ProcessingFeeService,
    private storeResolver: StoreResolver,
    private _settingResolver: SettingsResolver,
    private cdr: ChangeDetectorRef,
    private stationService: StationService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeStore();
    this.subscribeStripeProcessingFees();
    this.getBasicInfo();
    this.getCardReaderInfoFromCookie();
    this.subscribeSelectedStation();
  }

  async openCustomDiscountModal() {
     try {
       const modal = await this.modalCtrl.create({
         component: CustomDiscountComponent,
         componentProps: {
           currentTotal: this.dataPayload.totalAmount
         }
       });

       await modal.present();

       const { data } = await modal.onDidDismiss();
       if (data) {
         console.log(data);
         const discount = data;
         this.applyDiscountToTotal(discount);
       }
     } catch (error) {
       console.error('Error opening custom discount modal:', error);
     }
   }

  applyDiscountToTotal(discount) {
    if (discount.discountType === 'percentage') {
      const discountAmount = this.dataPayload.totalAmount * (discount.value / 100);
      this.dataPayload.totalAmount -= discountAmount;
    } else if (discount.discountType === 'custom') {
      this.dataPayload.totalAmount = this.dataPayload.totalAmount - discount.value; // Set the custom amount as the new total
    }

    // Ensure change detection is aware of the updated total amount
    this.cdr.detectChanges();
  }

  removeCents() {
    this.dataPayload.totalAmount = Math.floor(this.dataPayload.totalAmount);
    this.cdr.detectChanges();  // Ensure Angular detects the change and updates the UI
  }

  // New method to initiate connection with card reader
  connectWithCardReader() {
      this.cardReaderInitiated = true;  // Track that the card reader was initiated
      this.errorLog = null; // Reset error log on initiating connection
      this.readerStatus = 'Connecting to card reader...';
      this.getTerminalInstance();  // This initiates the reader connection only when the button is clicked
      this.cdr.detectChanges();  // Ensure the UI updates
  }



  subscribeStripeProcessingFees() {
    this.handleSubscription(this._settingResolver.stripeProcessingFee,
      (res) => {
        this.stripeProcessingFees = res;
      }
    );
  }

  calculateTotalAmount(dataPayload): number {
    const subtotal = dataPayload.totalAmount - dataPayload.processingFee;
    return this.processingFeeService.getCalculatedTotalAmountForStripeCardReader(this.dataPayload.totalAmount, subtotal, this.stripeProcessingFees) * 100;
  }

  calculatePlatformFeeAmount(amount:number): number {
    return this.processingFeeService.getCalculatedPlatformFeeAmount(this.dataPayload.totalAmount, this.stripeProcessingFees) * 100;
  }

  subscribeStore() {
    this.handleSubscription(this.storeResolver.selectedStore,
      (res) => {
        this.selectedStore = res;
        // First Attempt
        // this.getTerminalInstance(false); // not platform
      }
    );
  }


  /**
   * Subscribe Selected Station
   */
  subscribeSelectedStation() {
    this.stationService.selectedStation.subscribe(res =>{
      this.station = res;
      this.getDevice(this.station);
    });
  }


  closeCardReaderModel(response): void {
      this.clearReaderDisplay();
      this.errorLog = null; // Clear error log on closing the modal
      this.modalCtrl.dismiss(response);
  }


  getTerminalInstance(isPlatformAttempt: boolean = false) {
    const api = environment.baseUrl + 'payment-gateway/client/stripe/connection_token/' +
                 this.selectedStore.id + (isPlatformAttempt ? '/platform' : '');
    this.terminal = (window as any).StripeTerminal.create({
      onFetchConnectionToken: () => {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/json', 'tenant-name': localStorage.getItem('tenant') },
        };
        return fetch(api, options)
          .then(response => response.json())
          .then(data => data.secret);
      },
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect
    });
    this.discoverReaders(isPlatformAttempt)
  }

  async discoverReaders(isPlatformAttempt: boolean = false) {
      this.readerStatus = 'Finding card reader';
      const config = { simulated: environment.cardReader.simulated };
      const discoverResult = await this.terminal.discoverReaders(config);
      if (discoverResult.error) {
          this.errorLog = 'Failed to discover card reader: ' + discoverResult.error;
      } else if (discoverResult.discoveredReaders.length === 0) {
          this.errorLog = "No reader available.";
      } else {
          this.errorLog = null; // Clear error log if readers are found
          this.connectReader(discoverResult, isPlatformAttempt);
      }
  }


  async connectReader(cardReader, isPlatformAttempt: boolean = false) {
      const selectedReader = cardReader.discoveredReaders.find(reader =>
          reader.serial_number === this.cardReaderNumber || reader.serial_number === 'SIMULATOR'
      );
      this.readerStatus = 'Connecting with card reader...';
      const connectResult = await this.terminal.connectReader(selectedReader);

      if (connectResult.error) {
          this.errorLog = "Failed to connect, Error: " + connectResult.error;
      } else {
          this.errorLog = null; // Clear error log on successful connection
          this.readerStatus = 'Successfully connected';
          this.paymentStatus = 'Payment Initiated..';
          let calculatedAmount = isPlatformAttempt
              ? this.calculatePlatformFeeAmount(this.dataPayload.totalAmount)
              : this.calculateTotalAmount(this.dataPayload);

          if (calculatedAmount !== this.dataPayload.totalAmount * 100) {
              console.warn(`Discrepancy detected! Using input total amount: ${this.dataPayload.totalAmount}`);
              calculatedAmount = this.dataPayload.totalAmount * 100; // use input total amount if there’s a discrepancy
          }

          let stripeData = {
              amount: calculatedAmount,
              email: this.dataPayload.emailId,
              platformFee: this.dataPayload.platformFee
          };

          this.handleSubscription(
              isPlatformAttempt
                  ? this.stripePaymentService.getClientSecretForPlatform(stripeData, this.selectedStore.id)
                  : this.stripePaymentService.getClientSecret(stripeData, this.selectedStore.id),
              (res) => {
                  this.collectPayment(res.clientSecret, isPlatformAttempt);
              }
          );
      }
  }


  collectPayment(clientSecret, isPlatformAttempt: boolean = false) {
    this.paymentStatus = 'Payment Processing...';
    this.terminal.collectPaymentMethod(clientSecret).then(res => {
      if (res.error) {
        this.errorLog = "Error: " + res.error;
        this.clearReaderDisplay();
      } else if (res.paymentIntent) {
        this.paymentProcess(res.paymentIntent, isPlatformAttempt);
      }
    });
  }

  paymentProcess(paymentIntent, isPlatformAttempt: boolean = false) {
      this.paymentStatus = 'Payment processing almost finished...';
      this.terminal.processPayment(paymentIntent).then(result => {
          if (result.error) {
              this.errorLog = "Error: " + result.error;
              this.clearReaderDisplay();
          } else if (result.paymentIntent) {
              this.errorLog = null; // Clear error log on successful payment processing
              this.sendIntentToServer(result.paymentIntent, isPlatformAttempt);
          }
      });
  }


  sendIntentToServer(paymentIntent, isPlatformAttempt: boolean = false) {
    switch (this.dataPayload.type) {
      case "pay by orderId":
        this.checkoutByOrderId(paymentIntent.id, isPlatformAttempt);
        break;
      case "pay by order":
        this.checkout(paymentIntent.id, isPlatformAttempt);
        break;
      case "pay by paymentIntent":
        this.createCardPaymentForOrderByPaymentIntent(paymentIntent.id, this.dataPayload, isPlatformAttempt);
        break;
    }
  }

  createCardPaymentForOrderByPaymentIntent(paymentIntentId, paymentModel, isPlatformAttempt: boolean = false) {
    const temp = {
      amount: paymentModel.totalAmount,
      paymentType: "card",
      processingFee: paymentModel.processingFee
    };
    this.handleSubscription(
      isPlatformAttempt
        ? this.orderService.createCardPaymentForOrderByPaymentIntentForPlatform(paymentModel.orderId, paymentIntentId, temp)
        : this.orderService.createCardPaymentForOrderByPaymentIntent(paymentModel.orderId, paymentIntentId, temp),
      (res) => {
        this.paymentStatus = 'Payment is done successfully.';
        this.paymentDone = true;
        this.clearReaderDisplay();
        this.disconnectReader();
        this.closeCardReaderModel(paymentModel);
      },
      (err) => {
        this.errorLog = "Error in creating order.";
      }
    );
  }

  checkoutByOrderId(paymentIntentId, isPlatformAttempt: boolean = false) {
      console.log('checkoutByOrderId called with isPlatformAttempt:', isPlatformAttempt);

      this.dataPayload.paymentIntentId = paymentIntentId;
      this.dataPayload.paid = true;
      this.dataPayload.paymentType = 'card';
      const temp = {
        paymentIntentId: this.dataPayload.paymentIntentId,
        totalAmount: this.dataPayload.totalAmount,
        processingFee: this.dataPayload.processingFee
      };

      this.handleSubscription(
        isPlatformAttempt
          ? this.orderService.markCardOrderAsPaidForPlatform(this.dataPayload.id, temp)
          : this.orderService.markCardOrderAsPaid(this.dataPayload.id, temp),
        (res) => {
          if (isPlatformAttempt) {
            // Second Attempt
            console.log('Initiating platform-specific call');
            this.getTerminalInstance(true); // platform
          } else {
            this.paymentStatus = 'Payment is done successfully.';
            this.dataPayload.paid = true;
            this.dataPayload.paymentType = 'card';
            this.clearReaderDisplay();
            this.disconnectReader();
            this.closeCardReaderModel(this.dataPayload);
          }
        },
        (error) => {
          console.error('Failed to mark order as paid:', error);
          this.errorLog = 'Failed to mark order as paid. Please try again.';
          this.paymentStatus = 'Payment failed. Please check the connection.';
          this.paymentDone = false;
          this.cdr.detectChanges();
        }
      );
  }



  checkout(paymentIntentId, isPlatformAttempt: boolean = false) {
    const temp = this.dataPayload;
    temp.paymentIntentId = paymentIntentId;

    this.handleSubscription(
      isPlatformAttempt
        ? null // this.orderService.createOrderByCardReaderForPlatform(paymentIntentId, 'Stripe Card Reader', temp)
        : this.orderService.createOrderByCardReader(paymentIntentId, 'Stripe Card Reader', temp),
      (res) => {
        console.log('isPlatformAttempt:', isPlatformAttempt); // Logging the value of isPlatformAttempt

        if (res) {
          console.log('Response successful, setting isPlatformAttempt to true');
          isPlatformAttempt = true;
        }

        if (!isPlatformAttempt) {
          // Second Attempt
          // If it's the first attempt, initiate the second attempt
          this.getTerminalInstance(true); // platform
        } else {
          this.afterPayment(res);
        }
      }
    );
  }

  afterPayment(id: number) {
    console.log("After Payment");
    this.paymentStatus = 'Payment is done successfully.';
    this.paymentDone = true;
    this.clearReaderDisplay();
    this.disconnectReader();
    const temp = { id, paymentType: 'Card Reader' };
    this.closeCardReaderModel(temp);
  }

  getBasicInfo() {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  getCardReaderInfoFromCookie() {
    // this.cardReaderNumber = localStorage.getItem('card-reader-number');
    this.cardReaderNumber = this.device.deviceCode;
  }


  /**
   * get device
   */
  getDevice(station: any) {
    this.stationService.getDevice(station, 'terminal').subscribe(res =>{
      this.device = res;
    });
  }

  clearReaderDisplay() {
    if (this.terminal) {
      this.terminal.clearReaderDisplay();
    }
  }

  unexpectedDisconnect() {
    console.log('Disconnected from reader');
  }

  disconnectReader() {
    this.terminal.disconnectReader();
  }
}
