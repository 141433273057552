import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { CartResolver } from 'src/app/appResolvers/cart.resolver';
import { LogoutResolver } from 'src/app/appServices/logout/logout.resolver';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent extends HandleSubscriptionsComponent implements OnInit {
  customerInfo: any;
  showWarning = false;
  countdown = 10;
  countdownInterval: any;
  isUpdating = false;
  termsAndConditionsConfig: any;
  store: IStore;

  constructor(
    private modalCtrl: ModalController,
    private toaster: ToastMessageService,
    private _storeResolver: StoreResolver,
    private cashMgmtService: CashMgmtService,
    private _cartResolver: CartResolver,
    private _logoutResolver: LogoutResolver
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeSelectedStore();
    this.customerInfo = JSON.parse(localStorage.getItem('basic-info') || '{}');
  }


  /**
   * Subscribe Selected Store 
   */
  subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res => {
      if (res) {
        this.store = res;
        this.getTermsAndConditionsConfig();
      }
    });
  }

  acceptTerms(): void {
    this.updateTermsAndConditions(true);
  }

  declineTerms(): void {
    this.showWarning = true;
    this.startCountdown();
    this.updateTermsAndConditions(false);
  }

  /**
   * Get terms and condition config
   */
  getTermsAndConditionsConfig(): void {
    this.handleSubscription(this.cashMgmtService.getTermsAndConditionConfig(this.store.id),
      (res) => {
        this.termsAndConditionsConfig = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }


  /**
   * Update terms and condition config
   */
  private updateTermsAndConditions(accepted: boolean): void {
    this.isUpdating = true;
    const payload = {
      ...this.termsAndConditionsConfig,
      value: accepted ? '1' : '0'
    };
    this.handleSubscription(this.cashMgmtService.updateConfiguration(payload),
      (res) => {
        this.cashMgmtService.updateTermsAndConditionConfig(payload);
        this.sendEmail(accepted ? 'accepted' : 'declined');
      },
      (err) => {
        this.isUpdating = false;
        console.error('Error updating terms and conditions', err);
        this.toaster.errorToastWithClose('Failed to process your response.');
      }
    );
  }





  private sendEmail(status: string): void {
    const reportContent = `
      Business ${this.customerInfo.restaurantName} (${this.customerInfo.emailId}) has ${status} the terms and conditions.
      Account Information:
      Owner Name: ${this.customerInfo.ownerName}
      Email: ${this.customerInfo.emailId}
      Address: ${this.customerInfo.address}
      Mobile Number: ${this.customerInfo.mobileNumber}
      Timestamp: ${new Date()}
    `;

    const subject = `Terms and Conditions ${status.charAt(0).toUpperCase() + status.slice(1)}`;

    const templateParams = {
      subject: subject,
      reportContent: reportContent
    };

    emailjs.send('service_r2e7qet', 'template_frmv0ft', templateParams, 'P6dUNR0zfjoIGYgH4')
      .then((response: EmailJSResponseStatus) => {
        this.isUpdating = false;
        console.log('Email sent successfully', response);
        this.toaster.successToastWithClose(`Terms and conditions ${status} successfully.`);
        if (status === 'accepted') {
          this.modalCtrl.dismiss();
        }
      }, (error) => {
        this.isUpdating = false;
        console.error('Error sending email', error);
        this.toaster.errorToastWithClose('Failed to process your response.');
      });
  }

  private startCountdown(): void {
    this.countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(this.countdownInterval);
        this.onLogout();
      }
    }, 1000);
  }

  private onLogout(): void {
    this._cartResolver.clearCart();
    console.log('Logout Location: terms-and-condition.component | Line no 149');
    console.log('Logout reason: onLogout() clicked');
    this._logoutResolver.logout();
    localStorage.clear();
    this.modalCtrl.dismiss();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
