import { NgModule } from "@angular/core";
import { DisabledAccountComponent } from "./disabled-account.component";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { DesignUtilitiesModule } from "src/app/appModules/design-utilities.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [DisabledAccountComponent],
  imports: [IonicModule, CommonModule, ReactiveFormsModule, DesignUtilitiesModule],
  exports: [DisabledAccountComponent],
})
export class DisabledAccountModule {}