import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StripeCardPaymentComponent } from '../pricing-plans/stripe-card-payment/stripe-card-payment.component';

@Component({
  selector: 'app-disabled-account',
  templateUrl: './disabled-account.component.html',
  styleUrls: ['./disabled-account.component.scss'],
})
export class DisabledAccountComponent  implements OnInit {

  loading: boolean = false;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  async pay() {
    const modal = await this.modalCtrl.create({
      component: StripeCardPaymentComponent,
      mode: 'md',
      cssClass: 'rounded-modal',
      backdropDismiss: false,
    });
    await modal.present();
    const { role, data } = await modal.onWillDismiss();
    if (data=='success') {
      this.closeModal('success');
      window.location.reload();
    }
  }


  closeModal(action?:string) {
    this.modalCtrl.dismiss(action || '');
  }

}
