import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { StationService } from 'src/app/appServices/station.service';
import { EditTextComponent } from 'src/app/core/components/edit-text/edit-text.component';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-select-station',
  templateUrl: './select-station.component.html',
  styleUrls: ['./select-station.component.scss'],
})
export class SelectStationComponent extends HandleSubscriptionsComponent implements OnInit {

  store: IStore;
  selectedStationId: number;
  modalOpen: boolean = false;
  stationList: any;
  loading: boolean = false;

  // stationList = [
  //   { id: 1, stationName: 'Station 1' },
  //   { id: 2, stationName: 'Station 2' },
  //   { id: 3, stationName: 'Station 3' }
  // ];

  constructor(
    private modalCtrl: ModalController,
    private stationService: StationService,
    public _storeResolver: StoreResolver,
    private toasterService: ToastMessageService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeSelectedStore();
    this.getAllStation();
  }


  /**
   * Subscribe Selected Store
   */
  subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res =>{
      this.store = res;
    });
  }

  

  /**
   * subscribe store list
   */
  getAllStation() {
    this.handleSubscription(this.stationService.getAllStations(this.store.id),
      (res) => {
        this.stationList = res;
      },
      (err) => {
        
      }
    );
  }

  selectionChange(option: any) {
    this.selectedStationId = option.id;
  }

  closeModal() {
    this.modalCtrl.dismiss(null);
  }

  selectStation(){
    this.stationService.setSelectedStation(this.selectedStationId);
    this.closeModal();
  }

  /**
   * Edit Menu
   */
  async createStation() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      const modal = await this.modalCtrl.create({
        component: EditTextComponent,
        mode: 'md',
        cssClass: 'rounded-modal sml',
        componentProps: {
          data: {
            title: 'Station',
            text: '',
            icon: 'location-outline'
          }
        },
        backdropDismiss: false,
      });
      await modal.present();
      const { role, data } = await modal.onWillDismiss();
      if (data?.updatedText) {
        const payload = {
          storeId: this.store.id,
          stationName: data.updatedText
        }
        this.handleSubscription(this.stationService.createStation(payload),
        (res) => {
          this.getAllStation();
          this.toasterService.successToastWithClose('Station created successfully.');
        }, err => {
          this.toasterService.errorToastWithClose('Error in creating station: ' + err);
        });
      }
    }
    this.modalOpen = false;
  }


}
