import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { StationService } from 'src/app/appServices/station.service';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.page.html',
  styleUrls: ['./card-details.page.scss'],
})
export class CardDetailsPage implements OnInit {
  cardNumber: string;
  station: any;
  device: any;

  constructor(
    private modalCtrl: ModalController,
    private stationService: StationService
  ) { }

  ngOnInit() {
    this.cardNumber = localStorage.getItem("card-reader-number");
    this.subscribeSelectedStation();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  save() {
    if (!this.cardNumber) return;
    // localStorage.setItem('card-reader-number', this.cardNumber);
    this.patchDeviceCode(this.cardNumber);
    this.closeModal();
  }


  /**
   * get device
   */
  getDevice(station: any) {
    this.stationService.getDevice(station, 'terminal').subscribe(res =>{
      this.device = res;
      this.cardNumber = this.device.deviceCode;
    });
  }


  patchDeviceCode(deviceCode: string) {
    this.stationService.patchDeviceCode(this.station, 'terminal', deviceCode).subscribe(res =>{
  
    });
  }


  /**
   * Subscribe Selected Station
   */
  subscribeSelectedStation() {
    this.stationService.selectedStation.subscribe(res =>{
      this.station = res;
      this.getDevice(this.station);
    });
  }

}
